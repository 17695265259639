import { createWebHistory, createRouter } from "vue-router";
import SignIn from "@/components/auth/SignIn.vue";

const authGuard = (to, from, next) => {
  var isAuthenticated = false;
  let user = JSON.parse(localStorage.getItem("user"));
  if (user.access_token) isAuthenticated = true;
  else isAuthenticated = false;
  if (isAuthenticated) next();
  else next("/login");
};

const routes = [
  { path: "/", component: SignIn, meta: { title: "Sign In" } },

  {
    path: "/transactions",
    component: () => import("@/components/HCTRA-dashboard/Transaction.vue"),
    beforeEnter: authGuard,
    meta: { title: "Transactions Management", back: "vehicle" },
  },
  {
    path: "/transaction-logs",
    component: () => import("@/components/HCTRA-dashboard/EditLogs.vue"),
    beforeEnter: authGuard,
    meta: { title: "Transaction Logs" },
  },
  {
    path: "/manage-vehicles",
    component: () => import("@/components/HCTRA-dashboard/cl1.vue"),
    beforeEnter: authGuard,
    meta: { title: "Manage Vehicles", back: "transactions" },
  },
  {
    path: "/vehicle",
    component: () => import("@/components/HCTRA-dashboard/vehicles.vue"),
    beforeEnter: authGuard,
    meta: { title: "Vehicles" , back: "transactions" },
  },
  {
    path: "/unique-accounts",
    component: () => import("@/components/HCTRA-dashboard/AccountsManage.vue"),
    beforeEnter: authGuard,
    meta: { title: "Accounts", back: "transactions"  },
  },
  {
    path: "/profile-account",
    component: () => import("@/components/HCTRA-dashboard/AccountProfile.vue"),
    beforeEnter: authGuard,
    meta: { title: "Account Profile" ,  back: "transactions" },
  },

  { path: "/index.html", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkExactActiveClass: "active",
});

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title =
    "Innovative - " +
    (toRoute.meta && toRoute.meta.title ? toRoute.meta.title : "");
  next();
});

export default router;
