import api from "./api";
import TokenService from "./token.service";
import UtilsService from "./utils.service";

class AuthService {
  async login({ email, password }) {
    var data = {
      client_id: 2,
      client_secret: "lFNK11weWnJBrOFT6caNuM4O3Sd3zDm5nqXpb3oO",
      // client_secret: "9wCcVI6U5f7ly0Km2uvfMf7MONrnQ3BhcWWvnkPC",
      grant_type: "password",
      username: email,
      scope: "*",
      password: password,
    };

    let token_res = await api
      .post("/oauth/token", data)
      .then(async (response) => {
        if (response.data.access_token) {
          TokenService.setUser(response.data);

          await UtilsService.getAuthUser().then((respond) => {
            if (respond.data.data) {
              console.log(respond.data.data);
              let user = JSON.parse(localStorage.getItem("user"));
              localStorage.setItem("role",respond.data.data.data.role);
              localStorage.setItem(
                "employee",
                JSON.stringify(respond.data.data.data)
              );
              user.id = respond.data.data.id;
              user.email = respond.data.data.email;
              TokenService.setUser(user);
            }
          });
        }
        return response.data;
      });
    return token_res;
  }

  async logout() {
    await TokenService.removeUser();
  }
}

export default new AuthService();
