import axios from "axios";

const instance = axios.create({
  baseURL: "https://microservice.innovativetoll.com/",
  // baseURL: "http://localhost:8080/",
  // baseURL: "http://localhost:8000/",
  headers: {
    "Content-Type": "application/json",
  },
});

export default instance;
