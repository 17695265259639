import api from "./api";

class UtilsService {
  /* innovative */
  uniqdashboard(cl_level, client_id) {
    return api.get(`/dashboard?cl_id=${cl_level}&client_id=${client_id}`);
  }
  hctraVehicles() {
    return api.get(`/hctra_vehicles`);
  }
  hctraVehiclesStatus(status) {
    return api.get(`/hctra_vehicles?status=${status}`);
  }
  PosthctraVehicles(data) {
    return api.post(`/hctra_vehicles`, data);
  }
  getAuthUser() {
    return api.get(`/current_employee`);
  }
  TransactionsHCTRA(data) {
    return api.get(`/hctra_transactions${data}`);
  }
  AccountsHCTRA(data) {
    return api.get(`/hctra_balance?account=${data}`);
  }
  DeactivateHCTRAVehicle(account,lp,state, data) {
    return api.get(`/hctra_vehicles/6136949416/${lp}/${state}`, data);
  }
  TransactionsRangeHCTRA(data) {
    return api.get(`/transactions_range${data}`);
  }

}

export default new UtilsService();
